import styled from 'styled-components'
import { Theme } from '../../constants/variables'

//
// All the Buttons
//
// FUTURE: Review https://styled-components.com/docs/advanced
//

const Button = styled.button`
    text-transform: capitalize;
    display: inline-block;
    text-align: left;
    width: auto;
    position: relative;
    border: none;
    overflow:hidden;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    background-color: ${Theme.color.white};
    &:hover {
        -webkit-transition: max-width 0.5s ease-in-out, background-color 0.5s ease-in-out;
        -moz-transition: max-width 0.5s ease-in-out, background-color 0.5s ease-in-out;
        transition: max-width 0.5s ease-in-out, background-color 0.5s ease-in-out;
        background-color: ${Theme.color.primary};
        a {
            text-decoration: none;
        }
    }
    span {
        font-weight: 500;
        position: relative;
        color: ${Theme.color.dark};
        z-index: 10;
    }
`

const GrowButton = styled.button`
    display: inline-block;
    text-align: left;
    width: auto;
    max-width: 260px;
    position: relative;
    border: none;
    overflow:hidden;
    padding-top: ${Theme.spacing.two};
    padding-left: ${Theme.spacing.two};
    padding-bottom: ${Theme.spacing.two};
    padding-right: ${Theme.spacing.max};
    -webkit-transition: max-width 0.5s ease-in-out, width 0.5s ease-in-out;
    -moz-transition: max-width 0.5s ease-in-out, width 0.5s ease-in-out;
    transition: max-width 0.5s ease-in-out, width 0.5s ease-in-out;
    background-color: ${Theme.color.white};
    &:hover {
        max-width: 280px;
        width: 280px;
        color: ${props => props.color ? props.color : Theme.color.black};
        background-color: ${props => props.backgroundColor ? props.backgroundColor : Theme.color.primary};
        a {
            text-decoration: none;
        }
    }
    span {
        font-weight: 500;
        position: relative;
        color: ${Theme.color.black};
        z-index: 10;
    }
`

const GrowButtonLarge = styled.button`
    display: inline-block;
    text-align: left;
    width: auto;
    max-width: 310px;
    position: relative;
    border: none;
    overflow:hidden;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 80px;
    -webkit-transition: max-width 0.5s ease-in-out, width 0.5s ease-in-out;
    -moz-transition: max-width 0.5s ease-in-out, width 0.5s ease-in-out;
    transition: max-width 0.5s ease-in-out, width 0.5s ease-in-out;
    background-color: ${Theme.color.white};
    &:hover {
        max-width: 330px;
        width: 330px;
        color: ${props => props.color ? props.color : Theme.color.black};
        background-color: ${props => props.backgroundColor ? props.backgroundColor : Theme.color.primary};
        a {
            text-decoration: none;
        }
        span { 
            color: ${props => props.color ? props.color : Theme.color.black};
        }
    }
    span {
        font-weight: 500;
        position: relative;
        color: ${Theme.color.black};
        z-index: 10;
    }
`

export {
    Button,
    GrowButton,
    GrowButtonLarge,
}